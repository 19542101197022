<template>
    <div class="chats-wrapper">

       <button
        @click="chatGroupModal()"
        v-if="userData.userType === 'Admin' || userData.userType === 'Coach'"
        class="green-button add-new-programme"
      >
        New chat group
        </button>


        <div class="team-chat-wrapper" v-if="chatGroupData.length > 0">
            <div
                v-for="chat in chatGroupData"
                class="team-chat-list-item"
                :key="chat.id">

                <div class="chat-item" @click="chatDetailsModal(chat)" :id="chat.id">
                    <img :src="getImgUrl('default-image.jpg')" alt="" />
                    <p>{{ chat.name }}</p>
                    <span class="unreadMessages-wrapper hidden">
                        <span class="number-of-messages">0</span>
                    </span>
                </div>

                <div class="player-game-responce">
                      <b-button
                      @click="editchatGroupModal(chat)"
                      v-if="userData.userType === 'Admin' || userData.userType === 'Coach'"
                      variant="outline-warning"
                      v-b-tooltip.hover
                      title="Edit chat group"
                    >
                    <b-icon icon="pencil" aria-hidden="true"></b-icon>
                    </b-button>
                </div>
              </div>
            </div>
                <div v-else class="empty-state">
                    <img src="./img/emptyStateIllustration.svg" />
                    <p>No team chats</p>
                </div>


      <b-modal
      :id="`chatGroupModal-${selectedchatGroup.id}`"
      size="lg"
      :hide-footer="Boolean(true)"
      title="Chat group"
    >

    <div class="form__item">
      <input
        class="form__input full-size"
        type="text"
        placeholder="Name of group"
        v-model="selectedchatGroup.name"
      />
      <p v-if="selectedchatGroup.name === null">Please add a chat group name.</p>
    </div>

        <div class="user-select-wrapper" v-if="selectedchatGroup.usersFullData !== undefined">
          <p>Players in chat</p>
          <div class="player-list" v-for="(player, index) in selectedchatGroup.usersFullData" :key="player.id" :index="index">
            <label :for="player.id">
              <img
                v-if="
                  player.userImage !== '' &&
                  player.userImage !== null
                "
                id="user_photo"
                :src="getImgUrl(player.userImage)"
                alt=""
              />
              <img v-else :src="getImgUrl('default-image.png')" alt="" />
              {{ player.name }}
            </label>

            <b-button variant="outline-danger" @click="chatTeamMemberToggle(player.id, 'Remove')">Remove</b-button>
            </div>
          </div>

          <div class="players-not-in-chat" v-if="selectedchatGroup.teamMembersNotInChat !== undefined">
            <p>Players not in chat</p>

          <div class="user-select-wrapper">
            <div class="player-list" v-for="(player, index) in selectedchatGroup.teamMembersNotInChat" :key="player.id" :index="index">
              <label :for="player.id">
                <img
                  v-if="
                    player.userImage !== '' &&
                    player.userImage !== null
                  "
                  id="user_photo"
                  :src="getImgUrl(player.userImage)"
                  alt=""
                />
                <img v-else :src="getImgUrl('default-image.png')" alt="" />
                {{ player.name }}
              </label>
              <b-button variant="outline-success" @click="chatTeamMemberToggle(player.id, 'Add')">Add</b-button>
              </div>
            </div>
          </div>
      <p v-else>No players not in chat</p>
      <button
        v-if="editSelectedChatMode === false"
        @click="saveNewChatGroup()"
        class="green-button add-new-programme"
        >
        Add chat group</button>

        <button
          v-else
          @click="upDateChatGroup()"
          class="green-button add-new-programme"
        >
        Edit chat group</button>
  </b-modal>


  <b-modal
      :id="`chatDetailsModal-${selectedChat.id}`"
      modal-class="modal-chatDetails"
      size="xl"
      :hide-footer="Boolean(true)"
      :title="selectedChat.name"
      :static= true
      @close="detachListener"
    >

    <div class="chat-messages">
      <div v-for="message in selectedChatMessages" :class = "(message.userId == userData.id) ? 'chat-details receiver' : 'chat-details'" :key="message.id">

        <img
          v-if="
            message.userImageURL !== '' &&
            message.userImageURL !== null
          "
          :src="getImgUrl(message.userImageURL)"
          alt=""
        />
        <img v-else :src="getImgUrl('default-image.png')" alt="" />
        <div class="chat-wrapper">
        <span class="chat-name-time-wrapper">

          <span class="chat-name">{{message.name}}</span><span class="chat-time">- {{ getTimeFromDate(message.createdDate) }} 
            <b-icon :variant="`${checkMessageHasBeenRead(message) ? 'primary' : 'secondary'}`" font-scale="1.5" icon="check2-all" aria-hidden="true"></b-icon></span>

        </span>
        <span class="chat-message">{{message.messageContent}}</span>
      </div>
      </div>

      <div class="empty-state" v-if="selectedChatMessages.length === 0">
            <img src="./img/emptyStateIllustration.svg" />
            <p>No messages...</p>
        </div>

      <div class="helloo" ref="bottom"></div>
    </div>

    <div class="message-input">
        <div class="form__item">
            <!--<input
              class="form__input"
              type="text"
              placeholder="Type a message..."
              v-model="selectedChat.text"
            />-->

            <b-form-textarea
            class="form__input"
            id="textarea-default"
            placeholder="Type a message..."
            v-model="selectedChat.text"
            debounce="500"
          >
        </b-form-textarea>


            <emoji-picker @emoji="insert" :search="search">
                <button
                  class="emoji-invoker"
                  slot="emoji-invoker"
                  slot-scope="{ events: { click: clickEvent } }"
                  @click.stop="clickEvent"
                >
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current text-grey">
                      <path d="M0 0h24v24H0z" fill="none"/>
                      <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"/>
                  </svg>
                </button>
                <div slot="emoji-picker" slot-scope="{ emojis, insert, display }">
                  <div class="emoji-picker" :style="{ top: display.y + 'px', left: display.x + 'px' }">
                    <div class="emoji-picker__search">
                      <input type="text" v-model="search" v-focus>
                    </div>
                    <div>
                      <div v-for="(emojiGroup, category) in emojis" :key="category">
                        <h5>{{ category }}</h5>
                        <div class="emojis">
                          <span
                            v-for="(emoji, emojiName) in emojiGroup"
                            :key="emojiName"
                            @click="insert(emoji)"
                            :title="emojiName"
                          >{{ emoji }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </emoji-picker>
            <b-button id="save_chat_maeesage" @click="saveChatMessage()" variant="success">
              <b-icon icon="arrow-right-circle" aria-hidden="true"></b-icon
                  >
            </b-button>
          </div>
          

      </div>
  </b-modal>

    </div>
  </template>
  
  <script>
    import Vue from "vue";
    import { POPULATE_TEAM_CHAT_MESSAGES } from "@/store/actions/user.js";
    import { Chat } from "@/services/chat-service.js";
    import { Users } from "@/services/user-service.js";
    import { where, orderBy, query, limit, collection, getDoc, onSnapshot, addDoc, doc, updateDoc, arrayUnion, serverTimestamp, getCountFromServer} from "firebase/firestore";
    import db from "@/firebase/init.js";
    import EmojiPicker from 'vue-emoji-picker';
    import moment from "moment";
    import VModal from "vue-js-modal";

    const Filter = require('bad-words'),
    filterBadWords = new Filter();

  Vue.use(VModal);
  export default {
    name: "Chats",
    components: {EmojiPicker},
    data() {
      return {
        loadIngStuffText: "Loading",
        input: '',
        search: '',
        editSelectedChatMode: false,
        selectedChatMessages: [],
        chatGroupData: [],
        selectedChat: {
            text: "",
        },
        selectedchatGroup: {
            ageGroup: "",
            id: null,
            name: "",
            playerId: null,
            teamId: null,
            usersFullData: [],
            teamMembersNotInChat: [],
            usersInTheGroup: [],
        },
      };
    },
    props: {
        teamChatMessages: {
            type:Object,
            default: null,
        },
        currentTeam: {
            type: Array,
            default: null,
        },
        userData: {
        type: Object,
        default: null,
      },
      selectedTeam: {
        type: Object,
        default: null,
      },
    },

    watch: {
    selectedChatMessages: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => this.$refs["bottom"]?.scrollIntoView({ behavior: "smooth" }));
      },
    },
    teamChatMessages: function(newVal, oldVal) { // watch it
        console.log('Prop changed: ', newVal, ' | was: ', oldVal)
    },
  },
    created() {
        this.getTeamChatGroups();
    },
    methods: {
    async updateChatMessage() {
      try {
        const chatQuery = query(
          this.teamChatMessages,
          where("chatId", "==", this.selectedChat.id.toString()),
          where("clubId", "==", this.userData.clubId.toString()), 
          orderBy("createdDate", "desc"),
          limit(100)
        );

        this.chatListener = onSnapshot(chatQuery, 
          (snapshot) => {
            // Process snapshot updates
            snapshot.docChanges().forEach(async (change) => {
                console.log('change');
                console.log(change);
              if (change.type === 'modified' || change.type === 'added') {
                const messageData = change.doc.data();
                const messageId = change.doc.id;
                
                const shouldMarkAsRead = 
                messageData.readById === undefined || 
                !messageData.readById.includes(this.userData.id.toString());

                if (shouldMarkAsRead) {
                    try {
                    // Update document with new reader
                    const docRef = doc(db, 'team_chat_messages', messageId);
                    await updateDoc(docRef, {
                        readById: arrayUnion(this.userData.id.toString()),
                        lastReadAt: serverTimestamp()
                    });
                        
                    console.log('Message marked as read:', messageId);

                    } catch (error) {
                    console.error('Error updating read status:', error);
                    // Could throw error here to handle in calling code
                    throw new Error(`Failed to update read status: ${error.message}`);
                    }
                }
                this.$emit('getChatGroupInfo', {chatGroup: [this.selectedChat], collection: this.teamChatMessages });
            
              }
            });
          },
          (error) => {
            console.error('Snapshot listener error:', error);
          }
        );

      } catch (error) {
        console.error('Error in updateChatMessage:', error);
      }

      // Clean up function to detach listener
      return () => {
        if (this.chatListener) {
          this.chatListener();
          this.chatListener = null;
        }
      };
    },
    async saveChatMessage() {
      const docRef = await addDoc(collection(db, "team_chat_messages"), {
        chatId: this.selectedChat.id.toString(),
        messageContent: filterBadWords.clean(this.selectedChat.text),
        messageType: "sender",
        name: this.userData.name,
        userId: this.userData.id.toString(),
        media: "",
        userImageURL: this.userData.userImage,
        createdDate: new Date().toISOString(),
        clubId: this.userData.clubId,
        readById: [this.userData.id.toString()],

      }).then(() => {
            console.log("Document successfully written!");
            this.sendFBNotificationToAllIds();
          }).catch((error) => {
            console.error("Error writing document: ", error);
          });

    this.selectedChat.text = "";
    },
    async saveNewChatGroup() {
      if (this.selectedchatGroup.name !== "") {
        this.loadingStuff = true;
          const data = {
            action: "saveNewChatGroup",
            name: this.selectedchatGroup.name,
            usersInTheGroup: this.getAllSelectedUsersInTheGroupIds(),
            ageGroup: this.selectedTeam.age_group,
            teamId: this.selectedTeam.id,
            playerId: this.userData.id,
          };

          const responce = await Chat.find(data).catch((error) => {
            console.log(error);
          });

          this.loadingStuff = false;
          if (responce.status === 200) {
            this.getTeamChatGroups();
            this.$bvModal.hide(`chatGroupModal-${this.selectedchatGroup.id}`);
            this.$bvToast.toast("Chat group added", {
              title: "Chat group added",
              toaster: "b-toaster-bottom-right",
              solid: true,
              appendToast: true,
              variant: "success",
            });
          }

      } else {
        this.selectedchatGroup.name = "";
      }
    },
    async upDateChatGroup() {
      this.loadingStuff = true;

      const data = {
          id: this.selectedchatGroup.id,
          action: "upDateChatGroup",
          name: this.selectedchatGroup.name,
          usersInTheGroup: this.getAllSelectedUsersInTheGroupIds(),
          ageGroup: this.selectedchatGroup.age_group,
          teamId: this.selectedchatGroup.teamId,
          playerId: this.selectedchatGroup.playerId,
        };

          const responce = await Chat.find(data).catch((error) => {
            console.log(error);
          });

          this.loadingStuff = false;
          if (responce.status === 200) {
            this.getTeamChatGroups();
            this.$bvModal.hide(`chatGroupModal-${this.selectedchatGroup.id}`);
            this.$bvToast.toast("Chat group updated", {
              title: "Chat group updated",
              toaster: "b-toaster-bottom-right",
              solid: true,
              appendToast: true,
              variant: "success",
            });
          }
    },
    getAllSelectedUsersInTheGroupIds() {
      let playerIds = [];
      this.selectedchatGroup.usersFullData.map(player => {playerIds.push(player.id)});
      return playerIds.toString();
    },
    chatGroupModal() {
      this.editSelectedChatMode = false;
      this.selectedchatGroup = {
        ageGroup: "",
        id: null,
        name: "",
        playerId: null,
        teamId: null,
        usersFullData: this.currentTeam,
        teamMembersNotInChat: [],
        usersInTheGroup: [],
      };

      setTimeout(() => {
        this.showModal(`chatGroupModal-${this.selectedchatGroup.id}`);
        }, 100);
    },
    async getTeamChatGroups() {
      this.loadingStuff = true;
      const data = {
        action: "getTeamChatGroupsForUser",
        teamId: this.selectedTeam.id,
      };

      const responce = await Chat.find(data).catch((error) => {
        console.log(error);
      });

      this.loadingStuff = false;
      if (responce.status === 200) {
        this.chatGroupData = responce.data;
        this.$emit('getChatGroupInfo', {chatGroup: responce.data, collection: this.teamChatMessages });
      }
    },
    chatTeamMemberToggle(value, type) {
      const playerId = Number(value);

      const togglePlayer = (sourceArray, targetArray) => {
        const playerToMove = sourceArray.find(player => player.id === playerId);
        
        if (!playerToMove) return;
        const updatedSourceArray = sourceArray.filter(player => player.id !== playerId);
        const updatedTargetArray = [...targetArray, playerToMove];

        return {
          sourceArray: updatedSourceArray,
          targetArray: updatedTargetArray
        };
      };

      if (type === 'Remove') {
        const result = togglePlayer(
          this.selectedchatGroup.usersFullData, 
          this.selectedchatGroup.teamMembersNotInChat
        );

        if (result) {
          this.selectedchatGroup.usersFullData = [...result.sourceArray];
          this.selectedchatGroup.teamMembersNotInChat = [...result.targetArray];
        }
      }
      if (type === 'Add') {
        const result = togglePlayer(
          this.selectedchatGroup.teamMembersNotInChat, 
          this.selectedchatGroup.usersFullData
        );

        if (result) {
          this.selectedchatGroup.teamMembersNotInChat = [...result.sourceArray];
          this.selectedchatGroup.usersFullData = [...result.targetArray];
        }
      }
    },
    async editchatGroupModal(chatData) {
        if(this.currentTeam === null) {}
      this.editSelectedChatMode = true;
      this.selectedchatGroup.id = chatData.id;
      this.selectedchatGroup.ageGroup = chatData.ageGroup;
      this.selectedchatGroup.name = chatData.name;
      this.selectedchatGroup.playerId = chatData.playerId;
      this.selectedchatGroup.teamId = chatData.teamId;
      this.selectedchatGroup.usersInTheGroup = chatData.usersInTheGroup;
      this.selectAllPlayersInTheChat();
      setTimeout(() => {
        this.$bvModal.show(`chatGroupModal-${this.selectedchatGroup.id}`);
        }, 100);
    },
    selectAllPlayersInTheChat() {
        let usersInGroupData = this.selectedchatGroup.usersInTheGroup.split(",");
        if (this.currentTeam === null) { 
            this.selectedchatGroup.usersFullData = usersInGroupData.map(id => this.selectedTeam.playersInTeam.find(player => player.id === Number(id)));
            this.selectedchatGroup.teamMembersNotInChat = this.findMissingObjects(this.selectedTeam.playersInTeam, this.selectedchatGroup.usersFullData);
        } else {
            this.selectedchatGroup.usersFullData = usersInGroupData.map(id => this.currentTeam.find(player => player.id === Number(id)));
            this.selectedchatGroup.teamMembersNotInChat = this.findMissingObjects(this.currentTeam, this.selectedchatGroup.usersFullData);
        }
    },
    checkMessageHasBeenRead(message) {
      if (message.readById !== undefined) {
        return message.readById.includes(this.userData.id.toString())
      } else {
        return false;
      }
    },
    chatDetailsModal(chatData) {
      this.selectedChat = chatData;
      this.getTeamChatMessages();
      this.updateChatMessage();
        setTimeout(() => {
            this.showModal(`chatDetailsModal-${this.selectedChat.id}`);
        }, 100);
      
    },
    async getTeamChatMessages() {

        const q = query(
        this.teamChatMessages,
        where("chatId", "==", String(this.selectedChat.id)),
        where("clubId", "==", String(this.userData.clubId)),
        orderBy("createdDate", "asc"),
        limit(100)
        );

        this.chatListener = onSnapshot(q, (querySnapshot) => {
        this.selectedChatMessages = [];
        querySnapshot.forEach((doc) => {
        this.selectedChatMessages.push(doc.data());
        });

        });
    },
    sendFBNotificationToAllIds() {
      const idsList = this.selectedChat.usersInTheGroup.split(",");
      idsList.map(id => {
        this.sendFBNotificationMessage(`A new message has been posted to the ${this.selectedTeam.teamName} ${this.selectedChat.name} chat`, 
          `You have a new message to review from ${this.selectedTeam.teamName}`, 
          [], 
          Number(id), 
          false);
      });
      this.sendFBNotificationMessage(`A new message has been posted to the ${this.selectedTeam.teamName} ${this.selectedChat.name} chat`, 
        `You have a new message to review from ${this.selectedTeam.teamName}`, 
        [], 
        this.selectedTeam.coach_id, 
        true);
    },
    findMissingObjects(array1, array2, comparisonKey = 'id') {
    return array1.filter(obj1 => 
            !array2.some(obj2 => obj2[comparisonKey] === obj1[comparisonKey])
        );
    },
    async sendFBNotificationMessage(title, body, ageGroup, playerId, showToast) {
      if (ageGroup.length !== 0) {
        if (ageGroup.includes("2009")) { ageGroup.push("2010")}
        if (ageGroup.includes("2010")) { ageGroup.push("2009")}
        if (ageGroup.includes("2007")) { ageGroup.push("2008")}
        if (ageGroup.includes("2008")) { ageGroup.push("2007")}
      }

      const data = {
        action: "sendFBNotificationMessage",
        title: title,
        body: body,
        link: `${window.location.origin}/chats`,
        age_group: ageGroup,
        player_id: playerId
      }
      const responce = await Chat.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.status === 200 && showToast === true ) {
        this.$bvToast.toast("Notification sent", {
          title: "Notification sent",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }

    },
    detachListener() {
      this.chatListener();
      this.$emit("getNewDataFromCollection");
    },
    insert(emoji) {
      this.search = "";
      if (this.selectedChat.text !== undefined) {
        this.search = "h";
        this.selectedChat.text = this.selectedChat.text + emoji;
      } else {
        this.selectedChat.text = emoji;
        this.search = "h";
      }
    },
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    showModal(modelId) {
      this.$bvModal.show(modelId);
    },
    hideModal(modelId) {
      this.$bvModal.hide(modelId);
    },
    getTimeFromDate(date) {
      return moment(date).format("dddd, HH.mm");
    }
  }
}

  </script>
  <style scoped lang="scss">

  .green-button {
    margin-bottom:1rem;
  }

.team-chat-wrapper {
    width: 100%;
    .team-chat-list-item {
      padding: 2rem;
      margin-bottom: 2rem;
      padding: 1rem;
      background: white;
      padding: 1rem;
      border-radius: 4px;
      cursor: pointer;
      max-width: 43rem;
      -webkit-box-shadow: 0 4px 16px rgba(138, 138, 138, 0.3);
      box-shadow: 0 4px 16px rgba(138, 138, 138, 0.3);
      border: 1px solid transparent;
      margin-bottom: 2rem;
      position: relative;

        &:hover, &:focus {
        border: 1px solid #dcca0d;
            cursor: pointer;

      }

      .chat-item {
        p {
          margin:0;
        }
          img {
          width:6rem;
          margin: 0 auto 0.5rem auto;
          display:block;
        }
      }

      .player-game-responce {
        button {
          margin:0;
        }
      }

      .unreadMessages-wrapper {
        background: #e60000;
        width: 2rem;
        display: block;
        border-radius: 100px;
        text-align: center;
        color: white;
        font-size: 0.9rem;
        font-weight: bold;
        position: absolute;
        top: -1rem;
        right: 1rem;
        height: 2rem;
        line-height: 2rem;
        }
        .hidden {
            display:none;
        }
    }
}

.wrapper {
    position: relative;
    display: inline-block;
  }


.emoji-invoker {
    position: absolute;
    top: 2rem;
    right: 15%;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
    padding: 0;
    background: transparent;
    border: 0;
  }
  .emoji-invoker:hover {
    transform: scale(1.1);
  }
  .emoji-invoker > svg {
    fill: #b1c6d0;
  }

  .emoji-picker {
    position: absolute;
    z-index: 1;
    font-family: Montserrat;
    border: 1px solid #ccc;
    width: 15rem;
    height: 20rem;
    overflow: scroll;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 1px 1px 8px #c7dbe6;
    top: 57px !important;
    left: 54% !important;
  }
  .emoji-picker__search {
    display: flex;
  }
  .emoji-picker__search > input {
    flex: 1;
    border-radius: 10rem;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    outline: none;
  }
  .emoji-picker h5 {
    margin-bottom: 0;
    color: #b1b1b1;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: default;
  }
  .emoji-picker .emojis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .emoji-picker .emojis:after {
    content: "";
    flex: auto;
  }
  .emoji-picker .emojis span {
    padding: 0.2rem;
    cursor: pointer;
    border-radius: 5px;
  }
  .emoji-picker .emojis span:hover {
    background: #ececec;
    cursor: pointer;
  }

.chat-messages {
  overflow-y: scroll;
    min-height: 30rem;
    max-height: 100rem;
    padding: 1rem;
    position: relative;
    background: #fafafa;

  .chat-details {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    img {
        height: 4rem;
        border-radius: 100px;
        border: 1px solid;
      }

    .chat-wrapper {
      background: #ece9e9;
    margin: 0 1rem 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    color: #13519d;
    max-width: 66%;

    .chat-name-time-wrapper {
      display: flex;
      span.chat-name {
        display: block;
        font-size: 0.9rem;
        color: #2a8ce2;
      }

      span.chat-time {
      font-size: 0.8rem;
      }

    }
    
  }

  }
  
  .chat-details.receiver {
    justify-content: flex-end;

    .chat-wrapper {
      background: #e1f5d7;
    }
  }
  
}

.player-list {
      border-bottom: 1px solid #dee2e6;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
      img {
        width: 50px;
        margin-right: 0.5rem;
        border-radius: 100px;
        height: 50px;
        margin-bottom: 0.5rem;
      }

      &:hover, &:focus {
        cursor: pointer;
        background: #f8f8f8;
      }
    }

  

.message-input {
  position: sticky;
    width: 100%;
    background: white;
    padding: 1rem;
    left: 0;
    bottom: 0;

    .form__item {

      input.form__input, #textarea-default {
        width: 90%;
        margin-right: 1rem;
        font-weight: normal!important;
      }

      #save_chat_maeesage {
        position: absolute;
        right: 0%;
        top: 16px;
      }
    }
    
    }

</style>
  