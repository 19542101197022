<template>
  <div>

  <b-modal id="uploadPlayerMinsCSV" size="lg" :hide-footer="Boolean(true)" @close="resetGPSUploadModal">

    <div>
      <h2>Import CSV file</h2>
      <hr/>

      <div class="type-of-csv-import">

        <h3>{{ gameTrainingData.gameType}} {{ gameTrainingData.matchDate }} {{ gameTrainingData.teamName }} v {{ gameTrainingData.opposition}}</h3>

        <h4>Select data upload type</h4>

        <b-form-group class="radios-group-gps">
          <b-form-radio v-model="gpsUploadDataTypeSelected" name="catapult-radios" value="catapult"><img src="./img/cat-gps.jpg" /></b-form-radio>
          <b-form-radio v-model="gpsUploadDataTypeSelected" name="playermaker-radios" value="playermaker"><img src="./img/playMaker.jpg" /></b-form-radio>
        </b-form-group>
      </div>


        <div class="upload-wrapper" v-if="gpsUploadDataTypeSelected !== undefined">

          <label>File
            <input type="file" @change="importCSV( $event )"/>
          </label>

          <div v-if="csvDataImported !== null" class="show-imported-CSV-data">
            <pre>{{ csvDataImported }}</pre>
          </div>

          <button v-if="csvDataImported !== null" v-on:click="importUploadedCSVDataFile()">Save</button>
        </div>
        <p class="days-red-warning" v-if="dataCSVErrorMessage === null">
          <b-icon
            icon="exclamation-circle"
            aria-hidden="true"></b-icon>
          {{ dataCSVErrorMessage }}</p>
      </div>
  </b-modal>


  </div>
  
</template>
<script>
import { PlayerPhysicalPerformance } from "@/services/player-physical-performance-service.js";
export default {
  name: "GpsUploader",
  data() {
    return {
      gpsUploadDataTypeSelected: "",
      dataCSVErrorMessage: null,
      csvDataImported: null,
    };
  },
  props: {
    openGPSUploadModal: {
      type: Boolean,
      default: false,
    },
    gameTrainingData: {
      type: Object,
      default: () => {},
    }
  },
  watch: { 
    openGPSUploadModal: function(newVal) {
      if (newVal === true) {
        this.$bvModal.show("uploadPlayerMinsCSV");
        
      }
    }
  },
  created() {
  },
  methods: {
    async importCSV(e) {
      const file = e.target.files.item(0)
           const csv = await file.text();
            var lines = csv.split('\n')

            var result = []

            var headers = lines[0].split(',')

            for (var i = 1; i < lines.length; i++) {
                var obj = {}
                var currentline = lines[i].split(',')

                if (currentline.length === 1) {
                  console.log(currentline);
                } else {
                  for (var j = 0; j < headers.length; j++) {
                    console.log(currentline[j]);
                    let str1 = currentline[j].replace(/\n|\r/g, "");
                    let str2 = headers[j].replace(/\n|\r/g, "");
                      obj[str2] = str1
                  }
                  result.push(obj);
                }
            }
            this.csvDataImported = result;

    },
    resetGPSUploadModal() {
      this.$emit("gpsUploadModalValue", false);
    },
    async importUploadedCSVDataFile() {
      this.gpsUploadDataTypeSelected === "catapult"
      ? this.saveCSVCatapultData()
      : this.saveCSVPlayermakerData();
      },
      checkCSVdata() {
      return this.gpsUploadDataTypeSelected === "catapult"
      ? this.csvDataImported[0].hasOwnProperty("Accelerations Total Efforts")
      : this.csvDataImported[0].hasOwnProperty("Tag")
    },
    csvErrorMessage() {
      this.dataCSVErrorMessage = "Please verify that your CSV upload template is correct and that you have selected the appropriate upload option.";
      setTimeout(() => this.dataCSVErrorMessage = null, 2000);
    },
    async saveCSVCatapultData() {
      const dataIsGood = this.checkCSVdata();

      if (dataIsGood === true) {
        const data = {
          'action': "importUploadedCSVDataFile",
          data: this.csvDataImported,
          gameId: this.gameTrainingData.id,
          teamId: this.gameTrainingData.teamId
        }
        const responce = await PlayerPhysicalPerformance.find(data).catch((error) => {
          console.log(error);
        });
        if (responce.status === 200) {
          //this.loadPlayerMinsPerformanceData();
          this.$bvModal.hide("uploadPlayerMinsCSV");

          this.$bvToast.toast("CSV Catapult data added.", {
            title: "saved",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
        }

      } else {
        this.csvErrorMessage();
      }
    },
    fixDateForImport(date) {
      return date.substring(0, 10);
    },
    async saveCSVPlayermakerData() {
      const dataIsGood = this.checkCSVdata();
      if (dataIsGood === true) {
        this.csvDataImported[0].Date = this.fixDateForImport(this.csvDataImported[0].Date);
        const data = {
          'action': "importUploadedCSVDataForPlayMakerFile",
          data: this.csvDataImported,
          gameId: this.gameTrainingData.id,
          teamId: this.gameTrainingData.teamId
        }
        const responce = await PlayerPhysicalPerformance.find(data).catch((error) => {
          console.log(error);
        });
        if (responce.status === 200) {
          //this.loadPlayerMinsPerformanceData();
          this.$bvModal.hide("uploadPlayerMinsCSV");

          this.$bvToast.toast("CSV PlayMaker data added.", {
            title: "saved",
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          });
        }

      } else {
        this.csvErrorMessage();
      }
    },
  },
};
</script>
