<template>

<div class="player-injury-body-map" v-if="playerInjuryData.length > 0">
        <div v-for="injury in playerInjuryData" :key="injury.id">
        <div
            v-if="injury.injury_type === 'Left arm injury'"
            v-b-tooltip.hover="'Left arm injury'"
            class="injury-spot Left-arm-injury"
        >
            <span></span>
        </div>

        <div
            v-if="injury.injury_type === 'Right arm injury'"
            v-b-tooltip.hover="'Right arm injury'"
            class="injury-spot Right-arm-injury"
        >
            <span></span>
        </div>

        <div
            v-if="injury.injury_type === 'Left calf injury'"
            v-b-tooltip.hover="'Left calf injury'"
            class="injury-spot Left-calf-injury"
        >
            <span></span>
        </div>

        <div
            v-if="injury.injury_type === 'Right calf injury'"
            v-b-tooltip.hover="'Right calf injury'"
            class="injury-spot Right-calf-injury"
        >
            <span></span>
        </div>
        <div
            v-if="injury.injury_type === 'Left ankle injury'"
            v-b-tooltip.hover="'Left ankle injury'"
            class="injury-spot Left-ankle-injury"
        >
            <span></span>
        </div>
        <div
            v-if="injury.injury_type === 'Right ankle injury'"
            v-b-tooltip.hover="'Right ankle injury'"
            class="injury-spot Right-ankle-injury"
        >
            <span></span>
        </div>
        <div
            v-if="injury.injury_type === 'Left Knee injury'"
            v-b-tooltip.hover="'Left Knee injury'"
            class="injury-spot Left-Knee-injury"
        >
            <span></span>
        </div>
        <div
            v-if="injury.injury_type === 'Right Knee injury'"
            v-b-tooltip.hover="'Right Knee injury'"
            class="injury-spot Right-Knee-injury"
        >
            <span></span>
        </div>
        <div
            v-if="injury.injury_type === 'Left Quad injury'"
            v-b-tooltip.hover="'Left Quad injury'"
            class="injury-spot Left-Quad-injury"
        >
            <span></span>
        </div>
        <div
            v-if="injury.injury_type === 'Right Quad injury'"
            v-b-tooltip.hover="'Right Quad injury'"
            class="injury-spot Right-Quad-injury"
        >
            <span></span>
        </div>
        <div
            v-if="injury.injury_type === 'Left hamstring injury'"
            v-b-tooltip.hover="'Left hamstring injury'"
            class="injury-spot Left-hamstring-injury"
        >
            <span></span>
        </div>
        <div
            v-if="injury.injury_type === 'Right hamstring injury'"
            v-b-tooltip.hover="'Right hamstring injury'"
            class="injury-spot Right-hamstring-injury"
        >
            <span></span>
        </div>
        <div
            v-if="injury.injury_type === 'Left groin injury'"
            v-b-tooltip.hover="'Left groin injury'"
            class="injury-spot Left-groin-injury"
        >
            <span></span>
        </div>
        <div
            v-if="injury.injury_type === 'Right groin injury'"
            v-b-tooltip.hover="'Right groin injury'"
            class="injury-spot Right-groin-injury"
        >
            <span></span>
        </div>
        <div
            v-if="injury.injury_type === 'Left Hip injury'"
            v-b-tooltip.hover="'Left Hip injury'"
            class="injury-spot Left-Hip-injury"
        >
            <span></span>
        </div>
        <div
            v-if="injury.injury_type === 'Right Hip injury'"
            v-b-tooltip.hover="'Right Hip injury'"
            class="injury-spot Right-Hip-injury"
        >
            <span></span>
        </div>
        <div
            v-if="injury.injury_type === 'Right Shoulder injury'"
            v-b-tooltip.hover="'Right Shoulder injury'"
            class="injury-spot Right-Shoulder-injury"
        >
            <span></span>
        </div>
        <div
            v-if="injury.injury_type === 'Left Shoulder injury'"
            v-b-tooltip.hover="'Left Shoulder injury'"
            class="injury-spot Left-Shoulder-injury"
        >
            <span></span>
        </div>
        <div
            v-if="
            injury.injury_type === 'Right Wrist and hand injury'
            "
            v-b-tooltip.hover="'Right Wrist and hand injury'"
            class="injury-spot Right-Wrist-and-hand-injury"
        >
            <span></span>
        </div>
        <div
            v-if="
            injury.injury_type === 'Left Wrist and hand injury'
            "
            v-b-tooltip.hover="'Left Wrist and hand injury'"
            class="injury-spot Left-Wrist-and-hand-injury"
        >
            <span></span>
        </div>
        <div
            v-if="injury.injury_type === 'Head injury'"
            v-b-tooltip.hover="'Head injury'"
            class="injury-spot Head-injury"
        >
            <span></span>
        </div>
        <div
            v-if="injury.injury_type === 'Left foot injury'"
            v-b-tooltip.hover="'Left foot injury'"
            class="injury-spot Left-foot-injury"
        >
            <span></span>
        </div>
        <div
            v-if="injury.injury_type === 'Right foot injury'"
            v-b-tooltip.hover="'Right foot injury'"
            class="injury-spot Right-foot-injury"
        >
            <span></span>
        </div>
        </div>
        <img src="./img/body-sil.svg"  class="jhghjhjgjhgjhgjhg"/>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {};
    },
    props: {
        playerInjuryData: {
        type: Array,
        default: null,
      },
    },
}
</script>